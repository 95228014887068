.ImpactedEmployees {
  @include make-status-chart-box;
  &__labelContainer {
    display: flex;
    align-items: center;
    .IconButton {
      bottom: map-get($gutters, 'xxs');

    svg {
      @include make-square(24px);
    }
  }
  }
}
