.CreateUserModal {
  width: 800px !important;

  &__cancel-button {
    color: $color-white !important;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    margin-top: map-get($gutters, 's');
    margin-left: map-get($gutters, 's');
  }

  .FormFieldRenderer {

    &__value {
      width: 100%;
    }

    &__row {
      width: calc(50% - 20px);
      margin-right: 20px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  &__subheading {
    font-size: 20px;
  }
}
