.FlexHeroStat {
  width: 100%;
  min-width: 338px;
  min-height: 178px;
  background-color: #111;
  border: 1px solid rgba(255, 255, 255, 0.13);
  border-radius: $border-radius-8;
  padding: map-get($gutters, 'm');

  &__infoContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__labelContainer {
    display: flex;
    align-items: center;
  }
  &__statLabel {
    font-size: 16px;
    color: #dee0e2;
    font-weight: 500;
  }
  &__stat {
    font-size: 64px;
    font-weight: 500;
    color: $color-status-success;
    padding: 0 map-get($gutters, 'xs') 0 0;
    margin-bottom: 4px;
    &--offline {
      color: $color-status-error !important;
    }
    &--empty {
      color: $color-grey-still !important;
    }
  }

  &__time {
    font-size: 10px;
    color: #dee0e2;
    font-weight: 400;
  }
}

.IconButton {
  bottom: 10px;
  svg {
    @include make-square(12px);
  }
}
