.PendingContent {
  position: relative;
  &--curtain {
    &.PendingContent--loading {
      &.PendingContent:after {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($color-black, .8);
      }
    }
  }

  &--hide-content {
    &.PendingContent--loading {
      &.PendingContent:after {
        background-color: $color-black;
        z-index: 1000;
      }
    }
  }

  &__loader {
    position: absolute !important;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    z-index: 1001;
  }

  &__error {
    padding: 100px;
    text-align: center;
  }
}
