.CompaniesContainer {
  &__headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__search {
    padding-bottom: 48px;

    .Button {
      position: absolute;
      right: 0;
    }
  }

  &__no-data {
    text-align: center;
    color: $color-solid-secondary;
  }

  &__main-content {
    margin-bottom: 36px;

    .Table {
      &__header-row {
        color: #ffffff;
      }

      svg {
        @include make-square(24px);
      }
    }
  }

  &__pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: 36px;
  }

  &__modal {
    width: 700px !important;
  }

  &__header {
    &-text {
      font-size: 12px;
    }
  }

  &__modal-body {
    .Heading {
      display: grid;
      justify-content: center;
    }

    .Dropdown {
      margin-left: -15px;
    }
  }

  &__cancel-button {
    color: #ffffff !important;
  }

  &__dropdown {
    &-title {
      font-size: 12px !important;
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 18px;
  }

  &__form > * {
    flex: 1 1 297px;
  }

  &__subheading {
    font-size: 20px;
  }

  &__inputField {
    width: 297px !important;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
