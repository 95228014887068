.IndividualPoliciesContainer {
  &__headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__none-found {
    text-align: center;
    color: $color-solid-secondary;
  }

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .SearchInputField {
      border-radius: 29px;
      height: 48px;
      width: 25%;
    }
  }

  &__actionButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
  }

  &__main-content {
    margin-bottom: 36px;

    .Table {
      &__header-row {
        color: #ffffff;
      }

      svg {
        @include make-square(24px);
      }
    }
  }

  &__pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: 36px;
  }

  &__modal {
    width: 696px !important;

    &-header {
      text-align: left;
      justify-content: left;
    }
  }

  &__modal-row {
    width: 50%;
    margin-bottom: 18px;

    &:nth-child(2n) {
      width: calc(50% - 30px);
      margin-left: 30px;
    }

    &:nth-child(2) {
      align-items: flex-end;
    }

    &.ipv4 {
      &:after {
        content: '';
        display: flex;
        width: 50%;
      }
    }
  }

  &__modal-field {
    width: 100%;

    .TextToggler {
      align-items: flex-end;
    }

    .TextToggler {
      justify-content: flex-end;
    }
  }

  &__modal--message {
    display: flex;
    margin-top: 34px;
    margin-left: 30px;
    gap: 5px;
    align-items: center;
    font-size: 12px;

    &-suspend {
      color: $color-status-error;
    }

    &-warning {
      color: $color-status-warning;
    }
  }

  &__modal-body {
    max-height: 580px;
    overflow-y: scroll;
  }

  &__cancel-button {
    color: #ffffff !important;
  }

  &__dropdown {
    &-title {
      font-size: 12px !important;
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0;
    margin-top: 34px;
    padding: 0 36px;
  }
}
