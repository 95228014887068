.UploadModalRow {
  padding: map-get($gutters, 's');
  background-color: rgba($color-white, 0.04);
  display: flex;
  flex-direction: row;
  height: 56px;
  margin-bottom: map-get($gutters, 'xs');
  align-items: center;

  &__date {
    width: 200px;
  }

  &__status {
    width: 400px;
    .Spinner {
      top: -6px;
      margin-right: 18px;
    }
  }

  &__actions {
    margin-left: auto;
  }

  &__cta {
    color: $color-blue-hot-500 !important;
  }
}
