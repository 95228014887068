.FormFieldRenderer {
  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__heading {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 26px;
  }

  &__key {
    width: 25rem;
    display: flex;

    .Status__indicator-container {
      margin-left: 12px;
    }
  }

  &__value {
    display: flex;
    flex-direction: column;

    .InputField__messages {
      margin-top: map-get($gutters, 'xs');
    }
  }

  &__messages {
    margin: 4px 0;
  }

  &__message {
    display: flex;
    flex-direction: column;
    padding: 2px 0;
    font-size: 12px;

    &--error {
      color: $color-status-error;
    }
  }

  &__field {
    margin: 6px 0 0 !important;
    display: flex;
  }

  &__options {
    display: flex;
    align-items: center;
    margin-bottom: map-get($gutters, 'xs');
  }

  &__option-add-button {
    margin-top: map-get($gutters, 's');
  }
}
