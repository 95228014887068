.DHCPOptions {
  &__header {
    small {
      margin-right: map-get($gutters, 'xs');
    }
  }
  &__field {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: map-get($gutters, 'xs') 0;

    &-icon {
      @include make-square(16px);
    }

    &-message {
      display: flex;
      padding: 2px 0;
      font-size: 12px;

      &--error {
        color: $color-status-error;
      }
      margin-bottom: map-get($gutters, 'xxs');
      &:last-of-type {
        margin-bottom: map-get($gutters, 's');
      }
    }

    &--small {
      width: 48px !important;
    }
  }
  &__options {
    &-button {
      padding: 0 !important;
      height: 8px !important;
    }

    &-add-button {
      margin-top: map-get($gutters, 's');
    }
  }
}
