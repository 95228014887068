$medium-width: map-get($breakpoints, 'medium');
$large-width: map-get($breakpoints, 'large');

@mixin make-square($size, $force: false) {
  $suffix: '';
  @if ($force) {
    $suffix: !important;
  }

  width: #{$size}#{$suffix};
  height: #{$size}#{$suffix};
}

@mixin small-only {
  @media (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin medium-only {
  @media (min-width: #{$medium-width}) and (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}


@mixin small-only {
  @media (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin medium-only {
  @media (min-width: #{$medium-width}) and (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}
