.ConfigurationsContainer {
  &__template {
    &--desc {
    font-size: 12px;
    margin-bottom: 60px;
    }
    &--name {
      display: flex;
      svg {
        @include make-square(24px);
        transform: translateY(6px);
        margin-left: 8.5px;
        cursor: pointer;
      }
    }
    &--policy {
      color: $color-blue-hot-500;
      margin-top: 28px;
      cursor: pointer;
    }
  }

  .BreadcrumbNavigation {
    margin-bottom: 58px;
  }

  &__breadcrumb {
    margin-left: 0 !important;
  }

  &__headerContainer {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    &--collapseIcon {
      margin-left:auto;
      width:map-get($gutters, 'm');
      height:map-get($gutters, 'm');
    }
  }
  &__button {
    &-cancel {
      color: #FFFFFF !important;
    }
  }

  &__cards {
    margin: 24px 0;
    .Card {
      margin: 24px 0;
    }
  }
  &__config{
    display: flex;
    flex-direction: column;
    gap:24px;
    font-size: 16px;
    &-fields {
      display: flex;
      padding:12px 24px;

      &--icon {
        margin-left: auto;
      }
    }
    &-fields:hover {
      background: rgba(255, 255, 255, 0.13);
      cursor: pointer;
    }

  }
  &__status {
    .Status {
      &__label {
        color: #FFC500;
      }
    }
  }

  &__footer--button {
    display: flex;
    justify-content: center;
    margin-top:39px;
    gap:24px;
  }

  &__header--button {
    display: flex;
    justify-content: flex-end;
    margin-top:39px;
    margin-bottom: 30px;
    gap:24px;
  }

  &__header {
    align-items: center;
    margin-bottom: 36px;
    &-text {
      width: 269px;
      text-align: center;
      flex-direction: column;
      align-items: center !important;
      margin-left: auto;
    }
  }
  &__cancel-button {
    color: #FFFFFF !important;
  }
}
