.UploadModal {
  width: 700px !important;

  &__drop-area {
    border: 2px dashed rgba(white, 0.3);
    border-radius: 12px;
    padding: 50px 20px;
    text-align: center;
    cursor: pointer;
    filter: brightness(50%);

    &--active,
    &:hover {
      border: 2px dashed rgba(white, 1);
      color: $color-white;
      filter: brightness(100%);
    }

    svg {
      color: $color-status-success;
    }
  }

  &__csv-template {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    top: 30px;
    color: $color-solid-secondary;

    a {
      position: relative;
      bottom: 2px;
      font-size: $font-size-m;
      padding: 0px 5px 0px 5px;
      text-decoration: none;
      color: $color-blue-hot-300;
    }

  }

  &__info {
    text-align: center;
  }

  &__recent-uploads {
    &-title {
      text-align: center;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      margin-bottom: map-get($gutters, 'm');
      padding-top: map-get($gutters, 'm');
      border-top: 1px solid rgba($color-white, 0.13);
    }
  }

  &__details {
    width: 375px;
    text-align: center;
    margin: 0 auto;
    color: $color-solid-secondary;

    &-heading {
      font-size: 24px;
      line-height: 24px;
      font-weight: 400;
      color: $color-solid-tertiary;
      margin-bottom: map-get($gutters, 'm');
      margin-top: 0;
    }

    &-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: map-get($gutters, 'm');
      margin-top: map-get($gutters, 'm');
      border-top: 1px solid rgba($color-white, 0.13);
      text-align: center;
    }
  }
}