.AppLayout {
  display: flex;
  flex-direction: column;

  &__top-bar-left-slot {
    button > svg {
      @include make-square(24px);
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__drawer {
    position: sticky !important;
    z-index: 999;
    top: 0;
  }

  &__top-bar-center-slot {
    flex: 0 0 auto !important;
    margin-left: auto !important;
  }

  &__top-bar-right-slot {
    margin-left: 20px !important;
  }

  &__search {
    width: 300px !important;
  }

  &__drawer-heading {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    padding-left: 14px;
    padding-top: 30px;
  }
  &__partner-selector {
    &-label {
      text-overflow: ellipsis;
      width: 160px;
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
      text-align: left;
    }

    .DropdownItem__button {
      &-label {
        .text {
          text-overflow: ellipsis;
          width: 240px;
          display: inline-block;
          overflow: hidden;
        }
      }
    }
  }
  &__user {
    &--dropdown {
      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:nth-child(n) {
          font-weight: 400;
          font-size: $font-size-xs;
          line-height: 14px;
        }
      }
    }
  }
}
