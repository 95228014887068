.DropdownInput {
  &__list {
    max-width: 100%;
    z-index: 999 !important;
  }

  &__button {
    width: 320px;
  }

  &__icon {
    margin-left: auto;
  }

  &__message {
    font-size: 12px;
    padding-top: 4px;
  }

  &--error {
    padding-top: 10px;
    .DropdownInput__message {
      color: $color-status-error;
    }
  }
}
