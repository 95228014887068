.FormikSearchInputField {
  border-radius: 2px;
  padding: #{map-get($gutters, 's')};
  border: 1px solid rgba($color-white, 0.1) !important;
  background-color: rgba($color-white, 0.1);
  width: 367px !important;

  &--rounded {
    border-radius: $border-radius-24;
  }

  &--focus {
    border: 1px solid $color-solid-tertiary !important;
  }

  .InputField {
    &__icon {
      padding: 0;
    }
    &__input {
      background-color: transparent;
      padding: 0;
      border: none;
    }
    &__clear-icon {
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
}
