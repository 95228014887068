@mixin make-status-chart-box() {
  align-items: flex-start !important;
  border: solid rgba(255, 255, 255, 0.13) !important;
  border-radius: $border-radius-8 !important;

  .Heading {
    margin-left: 60px;
    text-align: left;
    padding: map-get($gutters, 's');
  }

  &__timeline {
    &--container {
      display: flex;
      width: 90%;
      &:nth-child(2) {
        margin-left:auto;
      }
    }
  }
  &__empty {
    &--container {
      display: flex;
     align-items: center;
     justify-content: center;
     width: 100%;
     height: 400px;
    }
  }
}

.EmployeeOnboarding {
  @include make-status-chart-box;
}
