.UsersContainer {
  width: 100%;

  &__no-data {
    text-align: center;
    color: $color-solid-secondary;
  }

  &__tabContainer {
    display: flex;
    justify-content: space-between;
  }

  &__tabs {
    width: 100%;
  }

  .Table {
    margin-top: map-get($gutters, xl);

    td {
      color: $color-solid-secondary;

      svg {
        @include make-square(24px);
        color: $color-white;
      }
    }
  }
}
