.CreateCompanyModal {
  &__modal {
    width: 800px !important;
    height: auto;

    .Dropdown {
      z-index: 11;
    }
  }

  &__cancel-button {
    color: $color-white !important;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    margin: map-get($gutters, 'l') map-get($gutters, 'xl') 0;

    &-section {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
    }

    &-row {
      width: calc(50% - #{map-get($gutters, 'm')});
      margin-bottom: map-get($gutters, 'l');

      &:nth-child(2n) {
        margin-left: auto;
      }

      &-value {
        width: 100%;
      }
    }
  }

  &__subheading {
    font-size: 20px;
    margin-bottom: map-get($gutters, 'l');
  }
}