.TemplateConfigurationSection {
  &__status {
    &-label {
      &--ok {
        color: $color-status-success !important;
      }
      &--warning {
        color: $color-status-warning !important;
      }
    }
    &-indicator {
      display: none;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .InputField {
      width: 372px;
    }
  }
  &__rowrow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__container {
    margin-top: auto;
  }
  &__key {
    width: 12rem;
    display: flex;
  }
  &__value {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #8f9397;
    &-header {
      display: flex;
      gap: 20px;
      padding-bottom: 10px;
      font-size: 12px;
      color: rgba(255, 255, 255, 1);
    }
  }
  &__options {
    &-button {
      padding: 0 !important;
      height: 8px !important;
    }
    &-field {
      display: flex;
      gap: 10px;
      align-items: center;
      margin: 8px 0;
      &-icon {
        @include make-square(16px);
      }
    }
    &-add-button {
      margin-top: map-get($gutters, 's');
    }
  }
  &__inputField {
    margin: -4px 0px 0px !important;
  }
  &__small-Field {
    width: 50px !important;
    margin: -4px 0px 0px !important;
  }

  .CollapseCard__content--open {
    max-height: 2000px;
  }
}
