@font-face {
  font-family: wigrumweb;
  font-weight: 400;
  src: url('assets/fonts/wigrumweb-regular.woff') format('woff');
}

@font-face {
  font-family: wigrumweb;
  font-weight: 500;
  src: url('assets/fonts/wigrumweb-medium.woff') format('woff');
}

@font-face {
  font-family: wigrumweb;
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/wigrumweb-bold.woff') format('woff');
}

@font-face {
  font-family: ocrey;
  font-weight: 300;
  src: url('assets/fonts/OC-Rey-Regular.otf') format('otf');
}

$font-family-default: 'wigrumweb';
$font-family-ocrey: 'ocrey';

$font-size-xxxs: 8px;
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 16px;
$font-size-l: 18px;
$font-size-xl: 24px;
$font-size-xxl: 28px;
$font-size-xxxl: 36px;
$font-size-xxxxl: 48px;

:export {
  font-family-default: $font-family-default;
  font-family-ocrey: $font-family-ocrey;
  font-size-xxs: $font-size-xxs;
  font-size-xs: $font-size-xs;
  font-size-s: $font-size-s;
  font-size-m: $font-size-m;
  font-size-l: $font-size-l;
  font-size-xl: $font-size-xl;
  font-size-xxl: $font-size-xxl;
  font-size-xxxl: $font-size-xxxl;
}
