@import 'src/definitions';
@import 'src/mixins';
@import 'src/utils';
@import 'src/typography';

* {
  box-sizing: border-box;
}

body {
  background-color: black;
  color: #c6c6c6;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

iframe {
  @include make-square(1px, true);
}

// Components

// Containers
.AppContainer {
  height: 100vh;
}
@import 'src/layouts/AppLayout';

// Containers
@import 'src/features/login/containers/LoginContainer';
@import 'src/components/PendingContent/PendingContent';
@import 'src/components/FormikInputSearchField/FormikInputSearchField';
@import 'src/components/FormikInputCheckboxGroup/FormikInputCheckboxGroup';
@import 'src/features/templates/containers/TemplatesContainer';
@import 'src/features/users/containers/UsersContainer';
@import 'src/features/individualPolicies/containers/IndividualPoliciesContainer';
@import 'src/features/companies/containers/CompaniesContainer';
@import 'src/features/configurations/containers/configurationContainer';
@import 'src/features/status/containers/StatusContainer';
@import 'src/features/status/components/charts/EmployeeOnboarding/EmployeeOnboardingChartContainer';
@import 'src/features/status/components/charts/EmployeeStatus/EmployeeStatusChartContainer';
@import 'src/features/status/components/charts/ImpactedEmployees/ImpactedEmployeesChartContainer';
@import 'src/features/status/components/charts/IpsecTunnel/IpsecTunnelChartContainer';
@import 'src/features/status/components/charts/RadiusServer/RadiusServerChartContainer';
@import 'src/features/companies/components/CreateCompanyModal/CreateCompanyModal';
@import 'src/features/components/CreateUserModal/CreateUserModal';
@import 'src/components/DropdownInput/DropdownInput';
@import 'src/features/configurations/components/TemplateConfigurationSection/TemplateConfigurationSection';
@import 'src/features/configurations/FormFieldRenderer';
@import 'src/features/configurations/components/FormikDHCPOptionsPicker/FormikDHCPOptionsPicker';
@import 'src/components/FlexHeroStat/FlexHeroStat';
@import 'src/features/status/components/TimelinePicker/TimelinePicker';
@import 'src/features/status/components/MonthDropdown/MonthDropdown';
@import 'src/components/UploadModal/UploadModal';
@import 'src/components/UploadModal/UploadModalRow';
@import 'src/components/ConfirmationModal/ConfirmationModal';
@import 'src/features/customerSupport/containers/CustomerSupportContainer';