.LoginContainer {
  width: 100%;
  height: calc(100% - #{$topbar-height});
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: url('./../../../assets/svg/login-bg.svg');
  background-clip: border-box;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  flex-direction: column;

  &__box {
    color: $color-white;
    width: 400px;
    box-sizing: border-box;
    text-align: center;
  }

  &__heading {
    font-family: wigrumweb;
    font-weight: normal;
    color: $color-still400;
  }

  &__app-name {
    font-family: ocrey;
    font-weight: 500;
    font-size: 48px;
    color: $color-white;
  }

  &__login-button {
    display: flex;
    justify-content: center;
  }
}
