.CustomerSupportContainer {
  &__headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__save {
    padding-bottom: 48px;
    padding-right: 48px;
    display: flex;
    flex-direction: row-reverse;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    gap:24px;
    padding: 0 42px;

    &-row {
      width: calc(50% - #{map-get($gutters, 'm')});
      margin-bottom: map-get($gutters, 'l');

      &-value {
        width: 100%;
      }
    }
    .InputField {
      &__input {
        background-color: transparent;
      }
    }
  }

  &__info {
    .StaticCard__header {
      background-color: $color-opacity-DmWhite_13;
      height: 72px;
    }
    .Card__content {
      background-color: $color-still800;
    }
    .IconButton {
      bottom: 0px;
      svg {
        height: 24px;
        width: 24px;
      }
    }
    p {
      margin-left: 9px;
      display: flex;
      flex-direction: row;
      color: $color-solid-secondary;
    }

    p:not(:first-child) {
      margin-top: 24px;
    }
    &-title {
      width: 145px;
      color: $color-solid-tertiary;
    }
  }
}

