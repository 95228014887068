$breakpoints: (
  'medium': 770px,
  'large': 1200px,
);

// Spacings
// @TODO: Define some spacings
$gutters: (
  'xxs': 4px,
  'xs': 8px,
  's': 16px,
  'm': 24px,
  'l': 36px,
  'xl': 40px,
  'xxl': 48px,
  'ios-notch-safe': env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left),
);

// Colors
$color-white: #fff;
$color-black: #000;
$color-solid-secondary: #8f9397;
$color-solid-tertiary: #c6c6c6;
$color-status-error: #fa1478;
$color-status-warning: #ffc200;
$color-status-success: #17e3ae;
$color-grey: rgba(222, 224, 226, 1);
$color-opacity-DmWhite_13: rgba($color-white, 0.13);
$color-blue-hot-300: #999eff;
$color-blue-hot-500: #656cff;
$color-grey-still: #5c5c5c;
$color-still400: #5c5c5c;
$color-still800: #111111;
$color-opacity-DmWhite_13: #ffffff21;

// Color map
$colors: (
  'white': $color-white,
  'black': $color-black,
  'solid-secondary': $color-solid-secondary,
  'status-success': $color-status-success,
  'status-error': $color-status-error,
);

@each $colorName, $colorValue in $colors {
  .color-#{$colorName} {
    color: $colorValue;
  }
}

// Sizes
$topbar-height: 80.88px;

// Border Radius
$border-radius-8: 8px;
$border-radius-24: 24px;
$border-radius-28: 28px;
