.TemplatesContainer {
  &__headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: map-get($gutters, 'xl');
  }

  &__no-data {
    text-align: center;
    color: $color-solid-secondary;
  }

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .SearchInputField {
      border-radius: 29px;
      height: 48px;
      width: 25%;
    }
  }

  &__main-content {
    margin: map-get($gutters, 'm') 0;
  }

  &__block {
    &-wrapper {
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: $border-radius-8;
      width: 100%;
      height: 100px;
      background-color: #111111;
      padding: map-get($gutters, 'm');
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-main-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &-name {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $color-white;
    }

    &-icon {
      margin-left: map-get($gutters, 'xs');
      cursor: pointer;
      color: $color-solid-tertiary;
    }

    &-total {
      font-size: 12px;
      color: #8F9397
    }

    svg {
      @include make-square(24px);
    }
  }

  &__pagination {
    margin-left: auto;
    margin-right: map-get($gutters, 'l');
  }

  &__header {
    align-items: center;

    &-text {
      width: 100%;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      align-items: center !important;
    }
  }

  &__body {
    padding: 20px 0 map-get($gutters, 'xl') 0 !important;
  }

  &__cancel-button {
    color: $color-white !important;
  }

  .IconButton {
    svg {
      @include make-square(24px);
    }
  }
}